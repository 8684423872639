.spinner-container {
  position:fixed;
  top:50%;
  left:55%;
  transform:translate(-50%,-50%);
 
}

.spinner {
  border: 10px solid #f3f3f3;
  border-top: 10px solid #ea580c;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1s linear infinite;
}

  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  